<template>
  <!--图片上传组件-->
  <div>
    <el-upload ref="myUpload" class="upload-demo" :accept="uploadRequire.accept" :action="uploadUrl"
               :headers="myHeaders"
               :on-progress="handleProgress" :on-remove="handleRemove" :show-file-list="uploadRequire.showFile"
               :file-list="uploadRequire.list" :list-type="uploadRequire.listType"
               :data="uploadRequire.attachedParameters"
               :on-success="handleAvatarSuccess" :on-exceed="exceedFile" :limit="uploadRequire.count"
               :beforeUpload="beforeAvatarUpload" :disabled="uploadRequire.disabled">
      <template v-if="!uploadRequire.customUploadStyle">
        <el-button size="small" type="primary" :disabled="uploadRequire.disabled">{{ uploadRequire.btnName }}
        </el-button>
        <div slot="tip" class="el-upload__tip" v-if="uploadRequire.showTips">文件大小 ≤
          {{ uploadRequire.megaNum }}M，支持格式：{{ `${uploadRequire.fileType.join('、')}` }}
        </div>
      </template>
      <slot v-else name="updateStyle"></slot>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    uploadAskObj: Object,
  },
  data() {
    return {
      // 上传
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      uploadRequire: { //上传文件要求
        count: 0, // 上传文件数量 0表示不限制文件的数量
        size: '200*200', // 图片尺寸
        listType: 'picture', // 文件类型
        list: [], // 已文件的图片
        megaNum: 0, // 上传文件兆数 0表示不限兆数
        showFile: false, // 是否显示已上传文件列表
        showTips: false, // 是否显示上传提示
        btnName: '选择文件', // 按钮名称
        fileType: ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'gif', 'mp4', 'PDF', 'pdf', 'DOC', 'doc'], // 上传文件类型
        accept: "",
        uploadPath: "",
        attachedParameters: {},//上传附带参数
        disabled: false,
        customUploadStyle: false,//是否用插槽自定义上传样式
      },
      uploadUrl: "",
      ListArr: [],
    }
  },
  watch: {
    uploadAskObj: {
      handler() {
        this.matching()
      },
      deep: true,
    },
  },
  created() {
    this.matching()
  },
  methods: {
    // 匹配上传要求
    matching() {
      Object.keys(this.uploadAskObj).map((key1) => {
        Object.keys(this.uploadRequire).map((key2) => {
          if (key1 == key2) {
            this.uploadRequire[key2] = this.uploadAskObj[key1]
          }
        })
      })

      if (this.uploadAskObj.uploadUrl) {
        this.uploadUrl = this.$globalUrl() + this.uploadAskObj.uploadUrl
      }
      if (this.uploadAskObj.UploadAddress) {
        this.uploadUrl = this.$globalUrl() + this.uploadAskObj.UploadAddress + '?file=' + this.uploadRequire.uploadPath
      } else {
        this.uploadUrl = this.$globalUrl() + 'zyd-zgww/commonFile/upload?file=' + this.uploadRequire.uploadPath
      }
    },
    // 文件上传中
    handleProgress(event, file) {
    },
    // 移除上传的文件
    handleRemove(file, list) {
      this.ListArr = [];
      for (let i = 0; i < list.length; i++) {
        this.ListArr.push(list[i].response.data)
      }
      this.$emit('getDataList', this.ListArr);
    },
    // 文件上传成功时的钩子
    handleAvatarSuccess(response, file) {
      if (response.status == true) {
        this.$emit('getDataList', file);
      } else {
        this.$message.error(response.msg)
        //上传文件错误时清空上传文件
        this.$refs['myUpload'].clearFiles();
      }
    },

    // 文件超出个数限制时的钩子
    exceedFile(files, list) {
      if (this.uploadRequire.count > 0) {
        this.$message.error("只能选择" + this.uploadRequire.count + "个文件");
      }
    },

    // 限制文件大小跟格式
    beforeAvatarUpload(file) {
      let that = this;
      if (file.name.indexOf('\'') > -1) {
        this.$message.error(`文件${file.name}包含非法字符，请进行更名操作`)
        return false;
      }
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLocaleLowerCase()
      let testType = Boolean(that.uploadRequire.fileType.find(item => item == testmsg) == testmsg)
      if (!testType && this.uploadRequire.accept != '*') {
        that.$message.error(`上传文件只能是${that.uploadRequire.fileType.join('、')}格式!`);
        return false
      }
      const isLt2M = file.size / 1024 / 1024 <= this.uploadRequire.megaNum
      if (!isLt2M && this.uploadRequire.megaNum != 0) {
        this.$message.error("上传文件大小不能超过 " + this.uploadRequire.megaNum + "MB!");
        return false
      }
    },
  }
}
</script>

<style scoped>

</style>
