<template>
  <el-dialog title="新增三维资源" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             v-if="visible">
    <el-steps :active="active" align-center class="m_b2">
      <el-step title="基本信息" description="请填写资源基本信息"></el-step>
      <el-step title="上传文件" description="请上传资源文件"></el-step>
      <el-step title="设置主显" description="设置主要显示文件"></el-step>
      <el-step title="提交成功" description="资源信息提交成功"></el-step>
    </el-steps>
    <!--基本信息-->
    <el-form size="small" :model="inputForm" ref="inputForm" label-width="200px" v-show="active == 0">
      <el-row>
        <el-col :span="14">
          <el-form-item label="上传缩略图：" :rules="[{ required: true }]">
            <Upload v-if="visible" :uploadAskObj="uploadRequire" @getDataList="getDataList"></Upload>
          </el-form-item>
          <el-form-item label="资源名称：" prop="resourceName"
                        :rules="[{ required: true, whitespace: true, message: '资源名称不能为空', trigger: 'blur' }]">
            <el-input v-model.trim="inputForm.resourceName" maxlength="100"
                      placeholder="请输入资源名称（限100字）"></el-input>
          </el-form-item>
          <el-form-item label="资源类型：" prop="type">三维</el-form-item>
          <el-form-item label="类型编码：" prop="type">A000</el-form-item>
          <el-form-item label="资源简称：" prop="resourceFoshot">
            <el-input v-model.trim="inputForm.resourceFoshot" maxlength="100"
                      placeholder="请输入资源简称（限100字）"></el-input>
          </el-form-item>
          <el-form-item label="资源描述：" prop="resourceDesc">
            <el-input type="textarea" :rows="3" maxlength="100" v-model.trim="inputForm.resourceDesc"
                      placeholder="请输入资源描述（限100字）"></el-input>
          </el-form-item>
          <!--                    <el-col :span="14" class="m_r1">-->
          <!--            <el-form-item 获取藏品 prop="collectionNum"-->
          <!--              :rules="[{ required: true, whitespace: true, message: '藏品编码不能为空', trigger: 'blur' }]">-->
          <!--              <el-input v-model.trim="inputForm.collectionNum" placeholder="请输入藏品编码"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="4">-->
          <!--            <el-button type="text" @click="getCollection">获取藏品</el-button>-->
          <!--                    </el-col>-->
          <el-col :span="24" class="m_r1">
            <el-form-item label="关联藏品"
                          :rules="[{ required: true, whitespace: true, message: '请关联藏品!', trigger: 'blur' }]">
              <el-button type="primary" @click="relatedCollections">关联藏品</el-button>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="22" v-if="collectionShow">
          <el-form-item>
            <div class="b_d i_block p_all1 collStyle">
              <div class="p_r2"><span class="f_w">藏品名称：</span>{{ collectionMap.collectionName }}</div>
              <div class="p_r2"><span class="f_w">藏品分类：</span>{{ collectionMap.oneTypeName }}</div>
              <div class="p_r2"><span class="f_w">藏品类型：</span>{{ getTypeList(collectionMap.collectionType) }}</div>
              <div class="p_r2"><span class="f_w">是否鉴定：</span>{{ collectionMap.identified == 0 ? '是' : '否' }}</div>
              <div class="p_r2"><span class="f_w">级别：</span>{{ getLeaveList(collectionMap.collectionLevel) }}</div>
              <div class="p_r2"><span class="f_w">辅助账编号：</span>{{ collectionMap.helpNum }}</div>
              <div class="p_r2"><span class="f_w">总账编号：</span>{{ collectionMap.generalNum }}</div>
              <div class="p_r2"><span class="f_w">完残程度：</span>{{ collectionMap.integrity }}</div>
              <div class="p_r2"><span class="f_w">完残情况：</span>{{ collectionMap.integrityInfo }}</div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-col :span="12" v-for="(item, index) in dynamicForm" :key="index">
            <el-form-item v-if="item.onShow == 0" :label="`${item.fieldName}：`" :prop="item.fieldEname">
              <el-date-picker
                  v-if="item.detailsShowType == 3"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="inputForm[item.fieldEname]"
                  type="date"
                  placeholder="选择日期" class="w100i">
              </el-date-picker>
              <el-input v-else-if="item.fieldEname == 'resourceTitle'"
                        v-model.trim="inputForm[item.fieldEname]" maxlength="500" placeholder="请输入（限500字）"></el-input>
              <el-input maxlength="20" v-model.trim="inputForm[item.fieldEname]"
                        placeholder="请输入内容（限20字）" v-else></el-input>

            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <!--上传文件-->
    <!-- <div v-if="active == 1" class="flex_t_c"> -->
    <!-- <el-upload :headers="myHeaders" v-for="(item, index) in uploadList" :key="index"
                   class="upload-demo"
                   drag
                   :action="uploadUrl+'?fileLevelId='+item.id+'&resourceId='+resourceId"
                   multiple :file-list="fileList[item.id]" :on-remove="handleRemove"
                   :on-success="handleSuccess" :beforeUpload="beforeUpload">
            <div class="el-upload__text">上传{{item.fieldName}}文件<br/>（仅支持rar压缩文件）</div>
        </el-upload> -->
    <div v-show="active == 1" class="page">
      <uploader v-if="showUploader" :browse_button="idArr"
                :url="this.$globalUrl() + 'zyd-zgww/commonFile/plUpload'"
                chunk_size="3MB" :multi_selection="true" :max_retries="3" :filters="myfilters"
                :FilesAdded="filesAdded"
                :BeforeUpload="beforeUpload1" :Error="error" :headers="myHeaders" :UploadComplete="uploadComplete"
                :FileUploaded="fileUploaded" :UploadProgress="uploadProgress" @inputUploader="inputUploader"/>

      <!-- :ChunkUploaded="chunkUploaded" -->
      <div class="flex_c_c">
        <div class="text_center uploadBtn" v-for="(item, index) in uploadList" :key="index"
             @click="upband(item.id)"
             :id="item.id">
          <div>上传{{ item.fieldName }}文件</div>
          <div>（仅支持{{ allowFiles }}压缩文件）</div>
        </div>
        <!--                <el-button size="small" type="primary" v-for="(item, index) in uploadList" :key="index" @click="upband(item.id)" :id="item.id">上传{{item.fieldName}}文件</el-button>-->
      </div>
      <div class="text_center m_t2" v-if="files.length == 0">
        <img src="../../../../../../assets/img/upload.png">
      </div>
      <div class="contentBox">
        <div class="flex_l_c item" v-for="(item, index) in files" :key="index">
          <el-button class="delBtn" icon="el-icon-close" @click="deleteFile(item.id, index)" type="primary"
                     size="mini"
                     circle></el-button>
          <div class="uploadImg flex_c_c">
            <img src="../../../../../../assets/img/Scheme.png" v-if="item.name.substring(item.type.indexOf('.') + 1) == 'png'
              || item.name.substring(item.type.indexOf('.') + 1) == 'jpg'
              || item.name.substring(item.type.indexOf('.') + 1) == 'gif'
              ">
            <img src="../../../../../../assets/img/zipIcon.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'zip'
              || item.name.substring(item.type.indexOf('.') + 1) == 'rar'
              ">
            <img src="../../../../../../assets/img/xlsIcon.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'xls'
              || item.name.substring(item.type.indexOf('.') + 1) == 'xlsx'
              ">
            <img src="../../../../../../assets/img/word.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'doc'
              || item.name.substring(item.type.indexOf('.') + 1) == 'docx'
              ">
            <img src="../../../../../../assets/img/document.png" v-else>


          </div>
          <div class="flex1 p_r2">
            <el-row>
              <el-col :span="8" class="omit">{{ item.name }}</el-col>
              <!-- <el-col :span="4" class="omit text_center">{{tableData[index].eDirectoryName}}</el-col> -->
              <!-- <el-col :span="2" class="text_center">{{tableData[index].rate}}</el-col> -->
              <el-col :span="3" class="text_right"
                      v-if="item.status === 2 || item.status === 1 && item.percent > 0">
                <el-button type="text" v-if="!uploading" icon="el-icon-video-play"
                           @click="uploadStart()"></el-button>
                <el-button type="text" icon="el-icon-video-pause" @click="uploadStop()"
                           v-else></el-button>
              </el-col>
              <el-col :span="2" class="text_left"
                      v-if="item.status === 2 || item.status === 1 && item.percent > 0">{{ rate }}
              </el-col>
              <el-col :span="3" class="text_center">
                <span v-if="item.status === -1">正在计算MD5</span>
                <span v-if="item.status === 1 && item.percent === 0">等待上传</span>
                <span v-if="item.status === 4" style="color: brown">上传失败</span>
                <span v-if="item.status === 5" style="color: #009900">上传成功</span>
              </el-col>
            </el-row>
            <el-progress class="m_t1"
                         v-if="item.status === 2 && !percentflag || item.status === 1 && item.percent > 0 && !percentflag"
                         :text-inside="true" :stroke-width="15" :percentage="item.percent"></el-progress>
            <el-progress class="m_t1"
                         v-if="item.status === 2 && percentflag || item.status === 1 && percentflag && item.type.substring(item.type.indexOf('|') + 1) > 0"
                         :text-inside="true" :stroke-width="15" :percentage="percentUp"></el-progress>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!--设置主显-->
    <div v-if="active == 2" class="epiphany">
      <el-row :gutter="10" class="m_b2">
        <el-col :span="7">
          <el-input size="small" v-model="fileName" placeholder="请输入文件名称" clearable></el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="levelId" clearable placeholder="请选择" size="small" @change="searchFile">
            <el-option :label="item.fieldName" :value="item.id" v-for="(item, index) in uploadList"
                       :key="index"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="dataList" v-loading="loading" size="small" height="380px" row-key="id" default-expand-all
                :tree-props="{ children: 'fileVoList' }" class="table treeTb">
        <el-table-column prop="fileName" show-overflow-tooltip sortable align="left" label="文件名称">
        </el-table-column>
        <el-table-column prop="modelType" show-overflow-tooltip sortable label="数据分级">
          <template slot-scope="scope">
            <span>{{ formatField(scope.row.modelType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.fileVoList
              && (scope.row.fileVoList[0].onShow == null
                || scope.row.fileVoList[0].onShow != '0')" type="primary" plain
                       @click="epiphany(scope.row.id, 0)">设为主显
            </el-button>
            <el-button size="mini" v-if="scope.row.fileVoList
              && (scope.row.fileVoList[0].onShow != null
                && scope.row.fileVoList[0].onShow == '0')" type="danger" plain
                       @click="epiphany(scope.row.id, 1)">删除主显
            </el-button>
            <el-button v-if="scope.row.fileVoList" size="mini" type="danger" plain
                       @click="del(scope.row.folderOnlyId)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
                       :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
                       layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
    <div v-if="active == 3">
      <el-result icon="success" title="资源提交成功" :subTitle="rest"></el-result>
    </div>
    <div class="text_center btnBox">
      <el-button type="primary" @click="prev" size="small" v-if="active > 0 && active < 3">上一步</el-button>
      <el-button type="primary" @click="next" size="small" v-if="active < 2">下一步</el-button>
      <el-button type="primary" @click="next" size="small" v-if="active == 2">提交</el-button>
      <el-button type="primary" @click="confirm" size="small" v-if="active == 3">确定</el-button>
    </div>
    <coll-list ref="collectionList"
               @seltDataColl="(data)=>{this.collectionMap = data;this.collectionShow = true}"></coll-list>
  </el-dialog>
</template>


<script>
import Upload from "@/components/Upload/Upload"
import FileMd5 from '@/utils/file-md5'
import Uploader from "@/components/Upload/Uploader";
import CollList from "@/views/modules/dyyg/gather/resource/collList.vue";
import CollectionList from "@/views/modules/collection/business/module/collectionList.vue"; // 导入上传组件
export default {
  components: {CollectionList, CollList, Upload, Uploader},
  data() {
    return {
      visible: false,
      active: 0,
      // 缩略图上传要求
      uploadRequire: {
        fileType: ['jpg', 'png', 'jpeg'],
        showFile: true, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        megaNum: 10,
        showTips: true,
      },
      uploadUrl: this.$globalUrl() + "zyd-zgww/commonFile/exhibitionUpload",
      inputForm: {
        resourceName: '',
        resourceFoshot: '',
        writtenDate: '',
        resourceDesc: '',
        collectionNum: '',
        thumbPath: '',
        collectionName: "",
        resourceTypeCode: ""
      },
      moduleId: "946049961995272192",
      collectionShow: false,
      dynamicForm: [],
      rest: "",
      collectionMap: {
        id: "",
        collectionName: "",
        collectionLevel: "",
        collectionType: "",
        collectionTexture: "",
        collectionYears: ""
      },
      // 上传文件
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      fileList: {},
      // 设置主显
      fileName: '',
      idArr: ['955785177131384832', '955785211726004224', '955785249650900992'],
      dataList: [
        {
          name: '10987-234.obj',
          classification: '高模',
        }
      ],
      uploadList: [],
      resourceId: null,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalOriginal: 0,
      totalOriginalFlag: false,
      loading: false,
      levelId: null,
      showUploader: false,
      myfilters: {
        // mime_types :[{ title : "Image files", extensions : "jpg,gif,png,mp4,PDF,pdf,DOC,doc,avi" }],
        // max_file_size : '400kb', //最大只能上传400kb的文件
        prevent_duplicates: true //不允许选取重复文件},
      },
      tableData: [],
      eDirectoryName: "",
      rate: "",
      uploading: false,
      up: {},
      files: [],
      percent: 0,
      percentflag: false,
      percentUp: 0,
      eleId: '',
      ingFileLen: 0,
      overFileLen: 0,
      // 数组为允许上传的文件
      allowFiles: ['stl', 'zip', '7z', 'rar'],
      leaveList: [],
      typeList: [],
      topicLibraryId:'',//宣传专题库 新增时的专题库id
    }
  },
  mounted() {
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
    this.$axios(this.api.collection.listSelect).then(data => {
      if (data.status) {
        this.typeList = data.data
      }
    })
  },
  methods: {
    init(topicLibraryId) {
      this.getDynamicForm()
      this.topicLibraryId = topicLibraryId
      this.getFieldByResourceName()
      this.visible = true
      this.$nextTick(() => {
        this.active = 0;
        this.showUploader = false
        this.resourceId = null
        this.fileList = {}
        this.collectionShow = false
        this.inputForm = {
          resourceName: '',
          collectionName: "",
          resourceDesc: '',
          collectionNum: '',
          thumbPath: '',
          resourceTypeCode: "A000"
        }
        this.uploadList = []
      })
    },
    // 获取藏品
    inputUploader(up) {
      this.up = up;
      this.files = up.files;
    },
    upband(id) {
      this.eleId = id
    },
    getCollection() {
      if (this.inputForm.collectionNum == '') {
        this.$message('请输入藏品编号');
        return
      }
      this.collectionShow = !this.collectionShow;
      var that = this;

      let parma = {
        num: this.inputForm.collectionNum,
      }
      var reset = {
        id: "",
        collectionName: "",
        collectionLevel: "",
        collectionType: "",
        collectionTexture: "",
        collectionYears: ""
      }
      that.$axios(this.api.resources.getByNum, parma, "get").then((res) => {
        if (res.status) {
          if (res.data) {
            that.collectionMap = res.data
          } else {
            that.collectionMap = reset
          }
        } else {
          that.$message.error(res.msg);
        }
      })
    },

    //关联藏品
    relatedCollections() {
      this.$refs.collectionList.int(1)
    },

    // 获取业务字段
    getFieldByResourceName() {
      var that = this;
      let parma = {
        resourceName: "三维资源/数据匹配"
      }
      that.$axios(this.api.resources.getFieldByResourceName, parma, "get").then((res) => {
        if (res.status) {
          that.uploadList.push(...res.data);
          for (let index = 0; index < that.uploadList.length; index++) {
            const element = that.uploadList[index];
            that.fileList[element.id] = []
          }
        }
      })
    },
    formatField(modelType) {
      for (let index = 0; index < this.uploadList.length; index++) {
        const element = this.uploadList[index];
        if (element.id == modelType) {
          return element.fieldName
        }
      }
    },
    uploadComplete() {
      this.uploading = false;
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    getTypeList(type) {
      let data = this.typeList.filter(item => {
        return item.id == type
      })
      if (data.length) {
        return data[0].collectionTypeName
      }
    },

    uploadProgress(uploader, file) {
      this.bytesPerSec = this.up.total.bytesPerSec
      if (this.bytesPerSec < 0.1 * 1024) {                            //小于0.1KB，则转化成B
        this.rate = this.bytesPerSec.toFixed(2) + "B/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
        this.rate = (this.bytesPerSec / 1024).toFixed(2) + "KB/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
        this.rate = (this.bytesPerSec / (1024 * 1024)).toFixed(2) + "MB/s"
      } else {                                            //其他转化成GB
        this.rate = (this.bytesPerSec / (1024 * 1024 * 1024)).toFixed(2) + "GB/s"
      }

      if (file.size < 104857600) {
        this.percent = file.percent;
      } else {
        let uploaded = file.type.substring(file.type.indexOf("|") + 1)
        this.percentflag = file.type.substring(file.type.indexOf('|') + 1) * 1000 / 1000 > 0
        if (uploaded > 0) {
          let sjs = Math.round(Math.random() * 3);
          let p = (uploaded * 1000 / 1000) + sjs;
          if (p >= 100) {
            file.type = file.type.replace(uploaded, 99)
            this.percentUp = 99;
          } else if (p < 100) {
            file.type = file.type.replace(uploaded, p)
            this.percentUp = p
          }
        }
      }
    },
    fileUploaded(uploader, file, responseObject) {
      let data = JSON.parse(responseObject.response)
      if (data.code == 500) {
        if (data.msg == null || data.msg == '') {
          this.$message.error('系统异常，请稍后再试');
        } else {
          this.$message.error(data.msg);
        }
        this.files.map(item => {
          if (item.id == file.id) {
            item.status = 4
          }
        })
        return
      }
      if (this.files !== undefined && this.files.length > 0) {
        this.percentflag = false;
        this.percentUp = 0;
        this.rate = "0.00B/s";
        file.fileLevelId = data.data.fileLevelId
        file.paths = data.data.path
      }
    },
    filesAdded(up, files) {
      this.percent = 0;
      files.forEach((f) => {
        f.levelType = this.eleId
        let filename = f.name
        var suffix = filename.substring(filename.lastIndexOf(".") + 1);
        var suf = suffix.toLowerCase()
        if (this.allowFiles.indexOf(suf) == -1) {
          this.$message.error("请上传" + this.allowFiles.toString() + "格式文件！");
          f.status = 4;
          return;
        }
        f.status = -1;
        // f.type =this.eDirectoryName+"|0";
        this.tableData.push({
          eDirectoryName: filename
        })
        FileMd5(f.getNative(), (e, md5) => {
          f["md5"] = md5;
          f.status = 1;
          this.$axios(this.api.resources.getPercentage, {md5: f.md5, size: f.size}, 'get').then(res => {
            if (res.status) {
              if (res.data != 0) {
                // f.type =this.eDirectoryName+"|"+res.data;
              }
              this.files.forEach((f) => {
                if (f.status != 2) {
                  this.uploadStart();
                  this.uploading = true;
                }
              })
            }
          });
        });

      });


      // this.$refs['begin'];
      // this.$refs.begin.dispatchEvent(new MouseEvent('click'));
      // this.$emit("uploadStop","")
      // let aa = setTimeout(()=>{
      //     this.uploadStart();
      // },100)


    },
    uploadStart() {
      this.up.start();
      this.uploading = true;
    },
    uploadStop() {
      this.up.stop();
      this.uploading = false;
    },

    // 获取动态字段
    getDynamicForm() {
      var that = this;
      let parma = {
        masterTableId: this.moduleId,
        pageType: 1

      }
      that.$axios(this.api.rcPage.getTableByType, parma, "get").then((res) => {
        if (res.status) {
          that.dynamicForm = res.data
          that.dynamicForm.forEach(function (v) {
            v.fieldEname = that.toHump(v.fieldEname)
          })
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    // 获取上传缩略图数据
    getDataList(data) {
      var obj = data.response.data
      this.inputForm.thumbPath = obj.path
    },

    // // getByNum
    // getByNum(num) {
    //     let parma = {num: num}
    //     this.$axios(this.api.resources.getByNum, this.inputForm, "get").then((res) => {
    //         if (res.status) {
    //             that.resourceId = res.data
    //         }
    //     })
    // },

    // 上一步
    prev() {
      this.totalOriginalFlag = false
      this.active--
    },
    // 下一步
    next() {
      if (this.active == 0) {
        this.$refs['inputForm'].validate((valid) => {
          var that = this;
          if (valid) {
            if (this.inputForm.thumbPath == '') {
              this.$message.error(`请上传缩略图!`);
              return
            }
            if (that.collectionMap.id == '') {
              this.$message.error(`请关联藏品!`);
              return
            }
            this.inputForm.id = this.resourceId
            this.inputForm.collectionId = that.collectionMap.id
            this.inputForm.collectionName = that.collectionMap.collectionName
            that.$axios(this.api.resources.resources, this.inputForm, "post").then((res) => {
              if (res.status) {
                that.resourceId = res.data
                this.active++
                this.showUploader = true
              } else {
                this.$message.error(res.msg);
                return
              }
            })

          }
        })
      } else if (this.active == 1) {
        this.overFileLen = 0
        this.ingFileLen = 0
        if (this.files.length == 0) {
          this.$message('请上传文件');
          return
        }
        this.files.forEach(item => {
          if (item.status == 5) {
            this.overFileLen++
          } else if (item.status != 5 && item.status != 4) {
            this.ingFileLen++
          }
        })
        if (this.ingFileLen > 0) {
          this.$message('请等待上传文件完毕');
          return
        }
        if (this.overFileLen > 0 && this.ingFileLen == 0) {
          let param = {resourceId: this.resourceId}
          this.$axios(this.api.resources.cacheFileEntity, param, "get").then((res) => {
            if (res.status) {
              this.active++
              this.refreshList()
            }
          })
        }
      } else {
        // 提交
        console.log(this.totalOriginal, "==")
        if (this.totalOriginal == 0) {
          this.$message('新增文件数量为0，请重新上传文件资源');
          return;
        }
        this.loading = true
        let param = {resourceId: this.resourceId}
        if(this.topicLibraryId){
          this.$set(param,'thematicId',this.topicLibraryId)
        }
        this.$axios(this.api.resources.listFileSave, param, "get").then((res) => {
          if (res.status) {
            this.loading = false
            this.rest = "成功新增资源：" + this.inputForm.resourceName + "，文件数量" + this.totalOriginal + "个。"
            this.showUploader = false
            this.active++
          }
        })
      }
    },
    // 移除上传的文件
    handleRemove(file, fileList) {
      var folderPath = ""
      var fileLevelId = ""
      var id = file.uid
      if (typeof file.response === "undefined") {
        folderPath = file.url
        fileLevelId = file.fileLevelId
      } else {
        folderPath = file.response.data.path
        fileLevelId = file.response.data.fileLevelId
      }


      var that = this
      let param = {
        resourceId: that.resourceId,
        folderPath: folderPath,
        fileLevelId: fileLevelId
      }
      this.$axios(this.api.resources.delFilePath, param, "get").then((res) => {
        if (res.status) {
          var array = that.fileList[fileLevelId]
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.id == id) {
              array.splice(index, 1);
            }
          }
          that.$refs.upload.handleRemove(file)
        }
      })


    },
    // 限制文件大小跟格式
    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (this.allowFiles.indexOf(testmsg) == -1) {
        this.$message.error("上传文件只能是" + this.allowFiles.toString() + "格式文件！");
        return false
      }
    },
    beforeUpload1(up, file) {
      up.settings.url = this.$globalUrl() + 'zyd-zgww/commonFile/plUpload' + '?fileLevelId=' + file.levelType + "&resourceId=" + this.resourceId
      up.setOption("multipart_params", {
        "size": file.size,
        "md5": file.md5,
        "uploadPath": this.uploadRequire.uploadPath
      });
    },

    error(uploader, errObject) {
      this.uploading = false;
      switch (errObject.code) {
        case -100:
          this.$message.error("上传失败");
          break;
        case -200:
          this.$message.error("http网络错误");
          break;
        case -300:
          this.$message.error("发生磁盘读写错误时的错误代码，例如本地上某个文件不可读");
          break;
        case -400:
          this.$message.error("发生因为安全问题而产生的错误");
          break;
        case -500:
          this.$message.error("初始化时发生错误");
          break;
        case -600:
          this.$message.error("选择的文件太大");
          break;
        case -601:
          this.$message.error("选择的文件类型不符合要求");
          break;
        case -602:
          this.$message.error("选取了重复的文件");
          break;
        case -700:
          this.$message.error("图片格式错误");
          break;
        case -702:
          this.$message.error("文件大小超过系统所能处理的最大值");
          break;
        default:
          this.$message.error("内存错误");
      }
      // this.$message.error(errObject.message);
      // this.$message.error(`上传文件只能是${this.myfilters.mime_types[0].extensions}格式!`);
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file) {
      let f = {
        name: file.name,
        url: response.data.path,
        fileLevelId: response.data.fileLevelId,
        id: file.uid
      }
      this.fileList[response.data.fileLevelId].push(f)
    },

    searchFile(levelId) {
      this.levelId = levelId
    },
    // 设置主显数据查询
    refreshList(type) {
      if (type == 1) {
        this.pageNo = 1
      }
      let parma = {
        resourceId: this.resourceId,
        current: this.pageNo,
        size: this.pageSize,
        filename: this.fileName,
        levelId: this.levelId
      }

      this.$axios(this.api.resources.listFileCache, parma, 'get').then(data => {
        if (data && data.status) {
          //  删除全部文件打回上一步
          /*if(data.data.total == null || data.data.total == 0){
              this.active = 1
              return
          }*/
          this.dataList = data.data.list
          this.total = parseInt(data.data.total) || 0
          this.totalOriginal = parseInt(data.data.totalOriginal) || 0

          this.dataList.forEach(item => {
            this.$set(item, 'fileName', item.folderOnlyName)
            this.$set(item, 'id', item.folderOnlyId)
          })
        }
      })

    },
    // 设为主显
    epiphany(id, state) {
      this.$confirm(`确定${state == 1 ? '删除' : '设为'}主显吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          resourceId: this.resourceId,
          fileId: id,
          state: state
        }
        this.$axios(this.api.resources.setObjMaster, param, 'get').then(data => {
          if (data && data.status) {
            this.$message.success("操作成功")
            this.refreshList(this.levelId)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    deleteFile(id, index) {
      // this.percentflag=false;
      // this.percentUp=0;
      let file = this.up.getFile(id);
      // this.log.operTerm=file.name;
      // this.$axios(this.api.common.logSave,this.log,'post').then(data => {
      //     // this.$emit('refreshDataList')
      // })
      if (!file.paths) {
        this.tableData.splice(index, 1);
        this.up.removeFile(file);
        return
      }

      var that = this
      let param = {
        resourceId: that.resourceId,
        folderPath: file.paths,
        fileLevelId: file.fileLevelId
      }
      this.$axios(this.api.resources.delFilePath, param, "get").then((res) => {
        if (res.status) {
          that.tableData.splice(index, 1);    //删除数组中的该条数据
          that.up.removeFile(file);
          this.$message.success("删除成功")
        } else {
          this.$message.error("删除失败");
        }
      })
    },
    // 删除
    del(folderOnlyId) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          resourceId: this.resourceId,
          folderOnlyId: folderOnlyId
        }
        this.$axios(this.api.resources.delFile, param, 'get').then(data => {
          if (data && data.status) {
            this.refreshList()
          }
        })
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 确定
    confirm() {
      this.visible = false;
      this.fileName = null
      this.totalOriginal = 0
      this.levelId = null
      this.$emit('refreshDataList')
    },
  }
}
</script>

<style scoped>
.treeTb.table >>> .cell {
  text-align: left !important;
}

.el-upload-dragger .el-upload__text {
  padding-top: 60px !important;
  font-size: 12px;
  color: #aaa;
}

.upload-demo {
  margin: 0 10px;
}

.upload-demo >>> .el-upload {
  display: block;
}

.upload-demo >>> .el-upload-dragger {
  margin: 0 auto;
}

.epiphany {
  padding: 0 100px;
}

.btnBox {
  padding-top: 30px;
}

.contentBox {
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.contentBox .item {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0px 0px 6px #cecece;
  margin: 30px 20px;
  /*padding: 10px 20px 10px 0px;*/
  position: relative;
}

.item >>> .el-button.el-button--text {
  padding: 0;
  font-size: 18px;
}

.contentBox .delBtn {
  position: absolute;
  right: -10px;
  top: -10px;
}

.uploadImg {
  width: 100px;
  padding: 20px 0;
  border-right: 2px solid #efefef;
  margin-right: 30px;
}

.uploadImg img {
  width: 32px;
  height: 32px;
}

.progress-box {
  box-sizing: border-box;
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 8px 10px;
  background-color: #ecf5ff;
  font-size: 14px;
  border-radius: 4px;
}

.uploadBtn {
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 20px 20px;
  padding: 50px 40px;
  font-size: 12px;
}

.collStyle{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
